<script>
import VxDialogView from "@/components/vx/VxDialogView";
import ShiftForm from "@/components/daybook/shifts/ShiftForm";

import { CREATE_SHIFT } from "./ShiftMutation.gql";
import { hasSnackbarAccess } from "@/mixins/snackbar";

export default {
  name: "NewShiftDialog",
  components: {
    VxDialogView,
    ShiftForm,
  },
  mixins: [hasSnackbarAccess],
  methods: {
    async createShift(data) {
      var input = {
        input: {
          id: data.input.id,
          employeeId: data.input.employeeId,
          businessDate: data.input.businessDate,
          startTime: data.input.startTime,
          endTime: data.input.endTime,
          notes: data.input.notes,
          shiftEventsAttributes: data.input.createEvents,
        },
      };
      try {
        const {
          data: { errors },
        } = await this.$apollo.mutate({
          mutation: CREATE_SHIFT,
          variables: input,
        });
        // GraphQL errors
        if (errors && errors.length) {
          throw new Error(errors.join("; "));
        }
        // Success
        this.showSnackbar({ text: `Shift created successfully` });
        this.$router.go(-1);
      } catch (error) {
        this.showSnackbar({ text: `Error creating shift: ${error}` });
      }
    },
  },
};
</script>

<template>
  <VxDialogView title="New shift" data-testid="newShiftDialog">
    <ShiftForm @update="createShift" />
  </VxDialogView>
</template>

<style></style>
